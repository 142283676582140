import React from "react"
import SEO from "../../components/seo"
import Header from "../../components/header"

import WorkFooter from "../../components/portfolioItems/worksFooter"


import herospace from "../../../assets/images/portfolio_items/cs-football/cs-football-hero.png"
import football1 from "../../../assets/images/portfolio_items/cs-football/cs-football1.png"
import football2 from "../../../assets/images/portfolio_items/cs-football/cs-football2.png"
import football3 from "../../../assets/images/portfolio_items/cs-football/cs-football3.png"


export default () => 
<div>
    <SEO title={'Credit ICON Worldwide | Credit Suisse Football - Marketing Campaign Football'}
    description="Fan engaging marketing campaign to support the Swiss National Team at the world cup: discover more on ICON's portfolio!"
    canonical={'https://icon-worldwide.com/works/credit-suisse-football'}/>
        <Header bgcolor="#292929"/>
    <div id="portfolio-item">
        <div className="herospace">
            <img src={herospace} alt="Credit Suisse Football, ICON Worldwide, marketing campaign, portfolio" title="ICON Worldwide portfolio, marketing campaign, Credit Suisse Football"/>
            <div className="title"><h1>Credit Suisse Football<span></span></h1>
            <h2>Fan engaging marketing campaign</h2>
            </div>
        </div>
        <div className="portfolio-inner">
        <p>To support the Swiss national team at the world cup, we created this engaging fan campaign allowing users to upload their fan photos to the website and facebook fan page.</p>
        <div className="portfolio-images">
            <img src={football1} alt="Credit Suisse Football, ICON Worldwide, marketing campaign, portfolio" title="ICON Worldwide portfolio, marketing campaign, Credit Suisse Football"/>
            <div></div>
            <img src={football2} alt="Credit Suisse Football, ICON Worldwide, marketing campaign, portfolio" title="ICON Worldwide portfolio, marketing campaign, Credit Suisse Football"/>
            <img src={football3} alt="Credit Suisse Football, ICON Worldwide, marketing campaign, portfolio" title="ICON Worldwide portfolio, marketing campaign, Credit Suisse Football"/>
        </div>
        <WorkFooter previous="university-of-st-gallen" next="vifor-pharma"/>
        </div>
    </div>
</div>